<template>
  <div
    :class="`channels-list-slide ${!channelsList.hasClassroomChannel ? 'channels-list-slide__full__height' : ''}`"
  >
    <div class="channels-list-slide__swiper">
      <Swiper :options="swiperOption">
        <SwiperSlide
          v-for="(channel, index) in 3"
          :key="index"
        >
          <div class="channels-list-slide__slide">
            <skeleton-bone />
            <skeleton-bone />
            <skeleton-bone />
          </div>
        </SwiperSlide>
      </Swiper>
      <div
        slot="button-prev"
        class="channels-list-slide__slide__navigation channels-list-slide__slide__prev"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        > <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.9997 2.66683C8.63588 2.66683 2.66634 8.63636 2.66634 16.0002C2.66634 23.364 8.63588 29.3335 15.9997 29.3335C23.3635 29.3335 29.333 23.364 29.333 16.0002C29.333 8.63637 23.3635 2.66683 15.9997 2.66683Z"
          fill="url(#paint0_linear)"
          stroke="#B8BED5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /> <path
          d="M19 10L13 16L19 22"
          stroke="#B8BED5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /> <defs> <linearGradient
          id="paint0_linear"
          x1="15.9997"
          y1="29.3335"
          x2="15.9997"
          y2="2.66683"
          gradientUnits="userSpaceOnUse"
        > <stop stop-color="white" /> <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        /> </linearGradient> </defs> </svg>
      </div>
      <div
        slot="button-next"
        class="channels-list-slide__slide__navigation channels-list-slide__slide__next"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        > <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.9997 2.66683C8.63588 2.66683 2.66634 8.63636 2.66634 16.0002C2.66634 23.364 8.63588 29.3335 15.9997 29.3335C23.3635 29.3335 29.333 23.364 29.333 16.0002C29.333 8.63637 23.3635 2.66683 15.9997 2.66683Z"
          fill="url(#paint0_linear)"
          stroke="#B8BED5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /> <path
          d="M19 10L13 16L19 22"
          stroke="#B8BED5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /> <defs> <linearGradient
          id="paint0_linear"
          x1="15.9997"
          y1="29.3335"
          x2="15.9997"
          y2="2.66683"
          gradientUnits="userSpaceOnUse"
        > <stop stop-color="white" /> <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        /> </linearGradient> </defs> </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'ChannelsListSkeleton',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          prevEl: '.channels-list__slide__prev',
          nextEl: '.channels-list__slide__next',
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          767: {
            slidesPerView: 'auto',
            spaceBetween: 30,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      'channelsList',
    ]),
  },
}
</script>

<style lang="sass" scoped>
.channels-list-slide
  background: $color-ice
  position: relative

  &:after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 4px
    background: $color-main
    border-radius: 10px 10px 0 0

  &__full__height
    min-height: calc(100vh - 176px)

  &__swiper
    position: relative

  .swiper-container
    padding: 0 0 30px
    max-width: calc(100% - 200px)

    +mq-s
      padding: 0 24px 30px
      max-width: 100%

  .swiper-slide
    +mq-s
      width: 90%

  .channels-list-slide__slide
    min-height: 110px
    border-radius: 9px
    background: white
    padding: 13px
    cursor: pointer
    box-shadow: 0 1px 3px rgba(0,0,0,.25)

    .skeleton-bone
      height: 18px !important
      width: 90% !important
      left: 5%
      margin: 5px 0

    .skeleton-bone:first-child
      width: 30% !important

    &:hover
      +anim(200ms)
      box-shadow: 0 6px 12px rgba(0,0,0,.35)

    &__navigation
      position: absolute
      top: calc(50% - 33px)
      z-index: 9
      cursor: pointer
      outline: none
      width: 45px
      height: 45px
      display: flex
      align-items: center
      justify-content: center

      +mq-s
        display: none

      &:hover
        svg
          path
            +anim(200ms)
            stroke: $color-primary

      &.channels-list-slide__slide__prev
        left: 40px

      &.channels-list-slide__slide__next
        right: 40px

        svg
          transform: rotate(180deg)
</style>

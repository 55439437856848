<template>
  <div class="lecture-card-skeleton">
    <div class="lecture-skeleton">
      <skeleton-bone />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LectureCardSkeleton'
}
</script>

<style lang="sass">
.lecture-card-skeleton
  border-radius: 8px
  backface-visibility: hidden
  box-shadow: 0 1px 3px rgba(0,0,0,.25)
  background: $color-ink-light
  padding: 8px 0
  cursor: pointer
  background-color: white

  +mq-s
    padding: 35px 0

  &:hover
    +anim(200ms)
    box-shadow: 0 6px 12px rgba(0,0,0,.35)

  .lecture-skeleton
    width: 100%
    box-sizing: border-box
    align-items: center
    display: flex
    min-height: 52px

    .skeleton-bone
      height: 30px !important
      width: 90% !important
      left: 5%

    span
      background: none !important

    +mq-s
      flex-direction: column !important
      text-align: center !important
      min-height: 0
      width: 90%

</style>

<template>
  <div>
    <HomeBody />
  </div>
</template>

<script>

import HomeBody from '@/components/HomeBody'

export default {
  name: 'Home',
  components: {
    HomeBody
  }
}
</script>
<template>
  <div
    id="v-step-3"
    class="channel-body"
  >
    <div class="classroom-body">
      <div class="classroom-body__header">
        <div>
          <Icon type="book" />
          <h3>Sala de Aula</h3>
          <label>Videoaulas para cada capítulo do livro</label>
        </div>
        <div
          v-if="isMultipleGrades"
          class="select"
        >
          <multiselect
            v-if="isSuccessful || grades.length > 1"
            v-model="grade"
            :searchable="false"
            :allow-empty="false"
            :options="grades"
            track-by="name"
            label="name"
            @select="changeGrade"
          />
        </div>
      </div>
      <LectureListSkeleton v-if="loading" />
      <LectureList
        v-if="isSuccessful"
        :lectures="lectures"
        :grade="grade"
      />
      <ResourceNotFound
        v-if="isUnsuccessful"
        @refresh="loadClassroomChannel()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LectureList from '@/components/LectureList'
import ResourceNotFound from '@/components/ResourceNotFound'
import LectureListSkeleton from '@/components/LectureListSkeleton'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ClassroomChannel',
  components: {
    LectureList,
    ResourceNotFound,
    LectureListSkeleton,
    Multiselect
  },

  data() {
    return {
      loading: false,
      successful: false,
      lectures: [],
      grade: null,
      grades: [],
      isMultipleGrades: false
    }
  },

  computed: {
    isSuccessful() {
      return !this.loading && this.successful
    },

    isUnsuccessful() {
      return !this.loading && !this.successful
    }
  },

  mounted() {
    this.loadClassroomChannel()
  },

  methods: {
    ...mapActions(['getCategoriesByChannel', 'getCategoryByPath']),

    changeGrade(option) {
      this.grade = option
      this.loadClassroomChannel()
    },

    async loadClassroomChannel() {
      this.loading = true
      try {
        const categories = await this.getCategoriesByChannel(process.env.VUE_APP_START_CHANNEL)
        this.grades = categories

        if(!this.grade) {
          this.grade = categories[0]
        }

        if (categories && categories.length) {
          const lecturesPath = this.getLecturePath(this.grade)
          this.lectures = await this.getCategoryByPath(lecturesPath[0])

          this.isMultipleGrades = categories.length > 1
          this.successful = true
        }
      } catch (e) {
        this.successful = false
      } finally {
        this.loading = false
      }
    },

    getLecturePath(category){
      return category.links.map(link => {
        if (link.rel === 'next') {
          return link.href
        }
      })
    }
  }
}
</script>

<style lang="sass">
.classroom-body
  min-height: 80vh
  padding: 12px 64px
  background: white

  &__header
    position: relative
    padding: 12px 24px
    display: flex
    justify-content: space-between
    align-items: flex-end

    +mq-m
      display: block

    i
      height: 46px
      color: $color-main

    h3
      font-size: 36px
      padding: 8px 0
      color: $color-main

    label
      font-size: 16px
      font-weight: 100

      +mq-s
        max-width: calc(100% - 5px)

  +mq-s
    padding: 0

.channel-body
  background: rgba($color-primary-lightest, .4)
  &.tour-active
    &:before
      background: white

  &__videos-channels
    width: 10%

  &__line
    background: $color-main
    width: 100%
    height: 4px
    border-radius: 5px

.select
  width: 290px
  text-align: center
  text-align-last: center
  padding-bottom: 6px

  +mq-s
    width: 100%
</style>

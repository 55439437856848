<template>
  <div class="lecture-list">
    <LectureCard
      v-for="(lecture, index) in lectures"
      :id="index === 0 ? 'v-step-4' : ''"
      :key="lecture.id"
      :lecture="lecture"
      :grade="grade"
    />
  </div>
</template>

<script>
import LectureCard from '@/components/LectureCard'

export default {
  name: 'LectureList',
  components: {
    LectureCard
  },
  props: {
    lectures: {
      type: Array,
      required: true,
      default: () => []
    },
    grade: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="sass">
.lecture-list
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 32px
  padding: 12px 24px

  +mq-l
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 24px

  +mq-s
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 16px
</style>

<template>
  <div class="wrapper-title">
    <Icon :type="icon" />
    <div class="wrapper-title__text">
      <h2 class="wrapper-title__label">
        {{ title }}
      </h2>
      <p class="wrapper-title__description">
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WrapperTitle',
  props: {
    icon: {
      type: String,
      default: 'grid'
    },
    title: {
      type: String,
      default: ''
    },
  },
}
</script>

<style lang="sass" scoped>
.wrapper-title
  display: flex

  .feather
    overflow: initial

  &__text
    padding-left: 10px

  &__label
    font-size: 20px
    color: $color-main

  &__description
    font-size: 16px
    color: $color-ink-light
</style>

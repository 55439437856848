<template>
  <div
    :style="{'background-color': color}"
    class="lecture-card"
    @click="redirectToVideos(lecture)"
  >
    <div class="lecture-card__header">
      <div class="header__badge">
        <img
          :src="icon"
          class="badge-logo"
        >
      </div>
      <div class="card__title">
        <h4>{{ title }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LectureCard',
  props: {
    lecture: {
      type: Object,
      required: true
    },
    grade: {
      type: Object,
      required: true
    }
  },
  computed: {
    color() {
      return this.lecture.color
    },

    title() {
      return this.lecture.name
    },

    icon() {
      return this.lecture.icon
    }
  },
  methods: {
    redirectToVideos(lecture) {
      const selectedCategory = {
        name: lecture.name + ' - ' + this.grade.name,
        color: lecture.color,
        icon: lecture.icon
      }
      localStorage.setItem('selectedCategory', JSON.stringify(selectedCategory))

      const queryParameters = lecture.links.find((link =>{
        return link.rel === 'videos'
      })).href.split('?').pop()

      this.$router.push({ path: `/channels/${process.env.VUE_APP_START_CHANNEL}/videos?${decodeURIComponent(queryParameters)}`} )
    }
  }
}
</script>

<style lang="sass">
.lecture-card
  display: flex
  flex-direction: column
  align-items: flex-start
  border-radius: 8px
  backface-visibility: hidden
  box-shadow: 0 1px 3px rgba(0,0,0,.25)
  background: $color-ink-light
  position: relative
  height: 100%
  max-width: 100%
  padding: 8px 0
  box-sizing: border-box
  cursor: pointer
  +anim(200ms)

  +mq-s
    padding: 20px 0

  &:hover
    +anim(200ms)
    box-shadow: 0 6px 12px rgba(0,0,0,.35)

  &.tour-active
    &:before
      width: 100%
      height: 100%
      border-radius: 8px

  &__header
    height: 100%
    width: 100%
    box-sizing: border-box
    align-items: center
    display: flex

    span
      background: none !important

    .header__badge
      display: inline-flex
      padding: 0 12px

      +mq-s
        padding: 6px 12px 12px 12px

      .badge-logo
        height: 36px

        +mq-s
          height: 32px

    +mq-s
      flex-direction: column !important
      text-align: center !important

  .card__title
    width: 100%
    box-sizing: border-box
    color: white

    h4
      font-size: 18px
      color: white

    +mq-s
      bottom: 20px

    h2
      +mq-m
        font-size: 32px

      +mq-s
        font-size: 28px
</style>

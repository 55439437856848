<template>
  <div
    id="v-step-1"
    :class="`channels-list ${!channelsList.hasClassroomChannel ? 'channels-list__full__height' : ''}`"
  >
    <WrapperTitle
      icon="grid"
      title="Canais de Vídeos"
    >
      Explore os canais de vídeo da SAS Educação TV
    </WrapperTitle>
    <ChannelsListSkeleton v-if="channelsList.loading" />
    <ResourceNotFound
      v-else-if="channelsList.error"
      @refresh="refresh()"
    />
    <div
      v-else
      class="channels-list__swiper"
    >
      <Swiper :options="swiperOption">
        <SwiperSlide
          v-for="(channel, index) in channelsList.data"
          :key="index"
        >
          <div
            class="channels-list__slide"
            @click="goToCategories(channel)"
          >
            <div class="channels-list__slide__header">
              <div class="channels-list__slide__header__icon">
                <img
                  src="~@/assets/play-circle.svg"
                  alt="play"
                >
                <p class="channels-list__slide__header__text">
                  vídeos
                </p>
              </div>
              <Icon type="chevron-right" />
            </div>
            <div class="channels-list__slide__body">
              <h3 class="channels-list__slide__body__title">
                {{ channel.name }}
              </h3>
              <p
                v-if="channel.description.length"
                class="channels-list__slide__body__description"
              >
                {{ channel.description }}
              </p>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div
        slot="button-prev"
        class="channels-list__slide__navigation channels-list__slide__prev"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        > <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.9997 2.66683C8.63588 2.66683 2.66634 8.63636 2.66634 16.0002C2.66634 23.364 8.63588 29.3335 15.9997 29.3335C23.3635 29.3335 29.333 23.364 29.333 16.0002C29.333 8.63637 23.3635 2.66683 15.9997 2.66683Z"
          fill="url(#paint0_linear)"
          stroke="#B8BED5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /> <path
          d="M19 10L13 16L19 22"
          stroke="#B8BED5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /> <defs> <linearGradient
          id="paint0_linear"
          x1="15.9997"
          y1="29.3335"
          x2="15.9997"
          y2="2.66683"
          gradientUnits="userSpaceOnUse"
        > <stop stop-color="white" /> <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        /> </linearGradient> </defs> </svg>
      </div>
      <div
        id="v-step-2"
        slot="button-next"
        class="channels-list__slide__navigation channels-list__slide__next"
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        > <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.9997 2.66683C8.63588 2.66683 2.66634 8.63636 2.66634 16.0002C2.66634 23.364 8.63588 29.3335 15.9997 29.3335C23.3635 29.3335 29.333 23.364 29.333 16.0002C29.333 8.63637 23.3635 2.66683 15.9997 2.66683Z"
          fill="url(#paint0_linear)"
          stroke="#B8BED5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /> <path
          d="M19 10L13 16L19 22"
          stroke="#B8BED5"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        /> <defs> <linearGradient
          id="paint0_linear"
          x1="15.9997"
          y1="29.3335"
          x2="15.9997"
          y2="2.66683"
          gradientUnits="userSpaceOnUse"
        > <stop stop-color="white" /> <stop
          offset="1"
          stop-color="white"
          stop-opacity="0"
        /> </linearGradient> </defs> </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import WrapperTitle from '@/components/global/WrapperTitle'
import ChannelsListSkeleton from '@/components/ChannelsListSkeleton'
import ResourceNotFound from "@/components/ResourceNotFound";
import getNextPath from '@/mixins/getNextPath'
import getVideosPath from '@/mixins/getVideosPath'

export default {
  name: 'ChannelsList',
  components: {
    Swiper,
    SwiperSlide,
    WrapperTitle,
    ResourceNotFound,
    ChannelsListSkeleton,
  },
  mixins: [getNextPath, getVideosPath],

  data() {
    return {
      loading: false,
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          prevEl: '.channels-list__slide__prev',
          nextEl: '.channels-list__slide__next',
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          767: {
            slidesPerView: 'auto',
            spaceBetween: 30,
          },
        },
      },
    }
  },

  computed: {
    ...mapGetters([
      'channelsList',
    ]),
  },
  mounted() {
    this.getChannelsList()
  },

  methods: {
    ...mapActions([ 'getChannelsList', 'addSelectedCategory' ]),

    goToCategories(channel) {
      this.addSelectedCategory(channel)

      const selectedCategory = {
        name: channel.name,
        color: '#2CA4E8',
        icon: null
      }

      localStorage.setItem('selectedCategory', JSON.stringify(selectedCategory))

      const nextPath = this.getNextPath(channel.links)

      if (nextPath) {
        this.$router.push(`/channels${nextPath.split('channels').pop()}`)
      } else {
        this.$router.push(`/channels/${channel.id}/videos`)
      }
    },
    refresh() {
      this.getChannelsList()
    }
  }
}
</script>

<style lang="sass" scoped>
.channels-list
  background: $color-ice
  position: relative

  &:after
    content: ''
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    height: 4px
    background: $color-main
    border-radius: 10px 10px 0 0

  &__full__height
    min-height: calc(100vh - 176px)

  & ::v-deep .wrapper-title
    padding: 30px 60px

    +mq-s
      padding: 24px

  &__swiper
    position: relative

  .swiper-container
    padding: 0 0 30px
    max-width: calc(100% - 200px)

    +mq-s
      padding: 0 24px 30px
      max-width: 100%

  .swiper-slide
    +mq-s
      width: 90%

  .channels-list__slide
    min-height: 110px
    border-radius: 9px
    background: $color-primary
    padding: 13px
    cursor: pointer
    box-shadow: 0 1px 3px rgba(0,0,0,.25)

    &:hover
      +anim(200ms)
      box-shadow: 0 6px 12px rgba(0,0,0,.35)

    *
      color: white

    &__header
      margin-bottom: 20px
      +flex_sb

      &__icon
        display: flex
        align-items: center

      &__text
        text-transform: uppercase
        padding-left: 5px
        font-size: 10px

    &__navigation
      position: absolute
      top: calc(50% - 33px)
      z-index: 9
      cursor: pointer
      outline: none
      width: 45px
      height: 45px
      display: flex
      align-items: center
      justify-content: center

      +mq-s
        display: none

      &:hover:not(.swiper-button-disabled)
        svg
          path
            +anim(200ms)
            stroke: $color-primary

      &.swiper-button-disabled
        opacity: .4
        cursor: not-allowed
        pointer-event: none

      &.channels-list__slide__prev
        left: 40px

      &.channels-list__slide__next
        right: 40px

        svg
          transform: rotate(180deg)

        &.tour-active
          &:before
            background: white
            width: 55px
            height: 55px

    &__body__title
      font-size: 20px

    &__body__description
      color: $color-primary-lighter
      font-size: 13px
</style>

<template>
  <div class="channel-body">
    <ContentHeader />
    <TVWrapper>
      <ChannelsList />
      <ClassroomChannel v-if="channelsList.hasClassroomChannel" />
    </TVWrapper>
    <TourHome
      v-if="isTourActive"
      @skip="skipTour"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ContentHeader from '@/components/ContentHeader'
import ChannelsList from '@/components/ChannelsList'
import ClassroomChannel from '@/components/ClassroomChannel'
import tour from '@/mixins/tour'
const TourHome = () => import('@/components/TourHome')

export default {
  name: 'HomeBody',
  components: {
    ContentHeader,
    ChannelsList,
    ClassroomChannel,
    TourHome,
  },
  mixins: [
    tour,
  ],
  computed: {
    ...mapGetters([
      'isLoading',
      'onboarding',
      'channelsList',
    ]),
  },
  mounted() {
    this.clearSelectedCategories()

    localStorage.removeItem('selectedCategory')
  },
  methods: {
    ...mapActions([
      'clearSelectedCategories',
    ]),
  }
}
</script>

<style lang="sass">
  .channel-body
    &__videos-channels
        width: 10%
</style>
